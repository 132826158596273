<template>
  <section id="content">
    <title>Dino</title>
    <!-- Notification -->

    <section
      id="notification"
      data-dismissible="true"
      data-title=""
      data-expires=""
    >
      <div class="container">
        <p>
          Server Rental Available!! --
          <a href="/hosting/dedicated">See Offers
            <i class="fas fa-angle-right icon-right"></i
          ></a>
          </p>
      </div>
    </section>

    <!-- Content Row -->
    <section class="content-row content-row-color content-gamebanner">
          <div class="container">
            <header
              class="content-header content-header-large content-header-uppercase">
              <h1><mark>Dino Billing</mark></h1>
              <p>
                Dino is a feature-rich billing system which offers many features which make running your company easier.
              </p>
              <p></p>
            </header>
          </div>
    </section>
    <!-- Content Row -->
    <section class="content-row">
      <div class="container">
        <div class="column-row">
          <div class="column-50">
            <h3>
              <i class="fa-solid fa-gear icon-feature-inline"> </i>Fully customizable
            </h3>
            <p>
              Dino allows you to change designs and settings easily without outside assistance.
            </p>
          </div>
          <div class="column-50">
            <h3>
              <i class="fa-solid fa-cubes icon-feature-inline"></i>For Pterodactyl and KVM
            </h3>
            <p>
              Dino is currently designed to interface with Pterodactyl and Qemu as well as a handful of upstream API's for dedicated server deployment through partnered companies.
            </p>
          </div>
        </div>

        <div class="column-row">
          <div class="column-50">
            <h3>
              <i class="fa-solid fa-code-commit icon-feature-inline"></i>Open and transparent
            </h3>
            <p>
              We pride ourselves in keeping our community informed with our decisions and updates.
            </p>
          </div>
          <div class="column-50">
            <h3>
              <i class="fa-solid fa-sparkles icon-feature-inline"></i>Functional, yet also beautiful.
            </h3>
            <p>
              Dino is constantly trying to improve in order to give the best experience to you and your customers, because nobody wants a billing system that doesn't look the part.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Content Row -->
    <section class="content-row ">
      <div class="container">
        <header class="content-header">
          <h2>
            Plans
          </h2>
        </header>
        <div class="column-row align-center-bottom">
          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="../../public/img/logos/diskcraft3.png" alt="" height="120px">
                <h4 style="margin-top: 20px">
                  <mark>DiskCraft</mark>
                </h4>
              </div>
              <div class="product-price">
                ${{ $root.$children[0].floatPriceToString(0)
                }}<span class="term">/ month</span>
              </div>
              <div class="product-features">
                <ul>
                  <li><strong>Unlimited Nodes</strong></li>
                  <li><strong>Unlimited Servers</strong></li>
                </ul>
                <ul>
                </ul>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/diskcraft"
                >
                  <i class="fas fa-server icon-left"></i>GitHub
                </router-link>
              </div>
            </div>
          </div>

          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="../../public/img/logos/Dino-Free.png" alt="" height="120px">
                <h4 style="margin-top: 20px">
                  <mark>Free Tier</mark>
                </h4>
              </div>
              <div class="product-price">
                ${{ $root.$children[0].floatPriceToString(0)
                }}<span class="term">/ month</span>
              </div>
              <div class="product-features">
                <ul>
                  <li><strong>1 Node</strong></li>
                  <li><strong>10 Servers</strong></li>
                </ul>
                <ul>
                </ul>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/dino/versions/v1"
                >
                  <i class="fas fa-server icon-left"></i>Deploy Now
                </router-link>
              </div>
            </div>
          </div>

          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="../../public/img/logos/Dino-Startup.png" alt="" height="120px">
                <h4 style="margin-top: 20px">
                  <mark>Startup</mark>
                </h4>
              </div>
              <div class="product-price">
                ${{ $root.$children[0].floatPriceToString(10)
                }}<span class="term">/ month</span>
              </div>
              <div class="product-features">
                <ul>
                  <li><strong>3 Nodes</strong></li>
                  <li><strong>Unlimited Servers</strong></li>
                </ul>
                <ul>
                </ul>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/dino/versions/v1"
                >
                  <i class="fas fa-server icon-left"></i>Deploy Now
                </router-link>
              </div>
            </div>
          </div>

          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="../../public/img/logos/Dino-Enterprise.png" alt="" height="120px">
                <h4 style="margin-top: 20px">
                  <mark>Enterprise</mark>
                </h4>
              </div>
              <div class="product-price">
                ${{ $root.$children[0].floatPriceToString(20)
                }}<span class="term">/ month</span>
              </div>
              <div class="product-features">
                <ul>
                  <li><strong>Unlimited Nodes</strong></li>
                  <li><strong>Unlimited Servers</strong></li>
                </ul>
                <ul>
                </ul>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/dino/versions/v1"
                >
                  <i class="fas fa-server icon-left"></i>Deploy Now
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.searchMan {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.imgText {
  max-height: 200px;
}
.content-gamebanner {
  background: url(../../public/img/9851a8af7f34eb4413cc2165be044489.gif) center center no-repeat;
  background-size: cover;
  height: 250px;
  color: white;
}
</style>
<style scoped>
.loader {
    
    border: 5px solid #36373C;
    border-bottom-color: #39CC64;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.75s linear infinite;
    position: relative;
    top: 1.25px;
}

.loader-root {
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<script>
export default {
  name: "Home",
  components: {},
  beforeDestroy() {
    clearInterval(window.content_slider_rotation);
  },
};
</script>
<script>
export default {
    name: 'loadingSpinnerCenterLine',
    props: {
        size: {
            type: String,
            default: '24'
        },
        margin_top: {
            type: String,
            default: '0'
        }
    }
}
</script>
