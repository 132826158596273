import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //@ Versions
  {
    path: "/dino/versions/",
    name: "Dino Versions",
    component: () => import("../views/versions/versions.vue"),
  },
  {
    path: "/dino/versions/diskcraft",
    name: "DiskCraft",
    component: () => import("../views/redirects/v1.vue"),
  },
  {
    path: "/dino/versions/v1",
    name: "v1.0",
    component: () => import("../views/versions/version.2.0.vue"),
  },
  //@ Cloud
  {
    path: "/hosting/dedicated/",
    name: "Bare Metal",
    component: () => import("../views/cloud/hosting.den.vue"),
  },
  //@ Galactiq
  {
    path: "/terms-of-service",
    name: "Terms of Service",
    component: () => import("../views/galactiq/terms-of-service.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/galactiq/contact.vue"),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/galactiq/team.vue"),
  },
  //@ Partners
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/partners/partners.vue"),
  },
  //@ Redirects
  {
    path: "/discord",
    name: "Discord",
    component: () => import("../views/redirects/discord.vue")
  },
  {
    path: "/diskcraft",
    name: "DiskCraft",
    component: () => import("../views/versions/diskcraft.vue")
  },
  //@ Errors
  {
    path: "*",
    name: "404 not found",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;