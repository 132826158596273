<template>
  <div class="loader-root" :style="`height: fit-content`">
      <span class="loader" :style="`height: ${size * 1.5}px; width: ${size * 1.5}px;  border-width: ${(size / 4) - 0.5}px; top: ${margin_top}px`"></span>
  </div>
</template>
<template>
  <div class="footer-dark">
    <header id="header" class="header-dynamic header-shadow-scroll">
      <div class="container">
        <router-link class="logo" to="/">
          <img src="../public/img/logos/Dino-Banner.png" alt="" height="120px" />
        </router-link>
        <nav>
          <ul class="nav-primary">
            <li>
              <router-link to="/dino/versions">Versions</router-link>
            </li>
            <li>
              <router-link to="/hosting/dedicated">Dedicated</router-link>
            </li>
            <li>
              <a style="cursor: pointer">More</a>
              <ul>          
                <li>
                  <router-link to="/partners">Partners</router-link>
                </li>
                <li>
                  <router-link to="/team">Team</router-link>
                </li>
              </ul>                          
            </li>
            <li>
              <a class="button button-secondary" target="" href="https://preview.dinopanel.net">
              <i class="fa-solid fa-eye icon-left"></i>Demo</a>
            </li>
          </ul>
          <ul class="nav-secondary">
            <li></li> 
            <li> 
              <a href="/contact" target=""
                ><i class="fa-solid fa-address-book icon-left" style="margin-right: 4px"></i>Contact
                Us</a
              >
            </li>
            <li>
              <a href="https://discord.gg/pVH5EMeeEE" target="_blank">
                <i class="fab fa-discord icon-left"></i>Join Discord Server</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <router-view id="content"></router-view>
    <footer id="footer">
      <section class="footer-primary">
        <div class="container">
          <div class="column-row">
            <div class="column-33">
              <h5>
                <img src="../public/img/logos/Dino-Banner.png" alt="" width="200px">
              </h5>
              <p>
                Dino is a feature-rich billing system which offers many features which make running your company easier.
              </p>
            </div>
            <div class="column-75">
              <div class="column-row align-right-top">
                <div class="column-25">
                  <h5>
                    Versions
                  </h5>
                  <ul>
                    <li>
                      <router-link to="/dino/versions/v1"
                        >Dino v1.0</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/diskcraft">DiskCraft</router-link>
                    </li>
                  </ul>
                </div>
                <div class="column-25">
                  <h5>
                    Resources
                  </h5>
                  <ul>
                    <li>
                      <router-link to="/contact">Contact</router-link>
                    </li>                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="footer-secondary">
        <div class="container">
          <p>Copyright 2023 &copy; Dino<br /></p>
        </div>
      </section>
    </footer>
  </div>
</template>

<style></style>

<script>

export default {
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.$forceUpdate();
      window.scrollTo(0, 0);
    },
  },
  data() {
    return {
      period: 1,
      priceIndex: 1,
    };
  },
  methods: {
    setPeriod(per) {
      if (per == 1) {
        this.priceIndex = 1;
        this.period = 1;
      }

      if (per == 3) {
        this.priceIndex = 0.975;
        this.period = 3;
      }

      if (per == 6) {
        this.priceIndex = 0.96;
        this.period = 6;
      }
      if (per == 12) {
        this.priceIndex = 0.95;
        this.period = 12;
      }
      this.$forceUpdate();
    },
    floatPriceToString(input) {
      if (input == undefined) return;

      input = input * this.priceIndex;

      if (input.toString().includes(".")) {
        var returnStr = (Math.round(input * 100) / 100)
          .toString()
          .replace(",", ".");
        if (returnStr.split(".")[1] && returnStr.split(".")[1].length == 1)
          returnStr += `0`;

        if (returnStr.split(".")[1] == null) returnStr += `${"."}00`;

        return returnStr;
      } else {
        return input + `${"."}00`;
      }
    },
  },
};
</script>

<style>
#footer {
  background-color: #003554;
}
.content-slider {
  background-color: #19212c;
}

.content-row {
  background-color: #051923 !important;
  color: #a39e94;
}
.content-row-gray {
  background-color: #051923 !important;
}
.content-row-color {
  background-color: #051923 !important;
}

.product-box {
  background-color: #06202c !important;
  border-color: #00b07800;
  color: #E5E7EB;
  transition: all .15s ease-in-out;
}

.product-box:hover{
  transform: scale(1.03);
  animation: pulse 2s;
}

.pulse {
  margin:100px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #0582CA;
  box-shadow: 0 0 0 #0582CA;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #006494;
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #006494;
    box-shadow: 0 0 0 0 #006494;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.product-box .product-price {
  border-bottom: 1px solid #00b07800;
}
.product-box.product-box-popular {
  border-color: #00b07800 !important;
}
.product-box .product-popular {
  background-color: #10B981 !important;
}
.tab-group.tab-group-switch-style > ul {
  background-color: #0C111B !important;
}
.tab-group.tab-group-switch-style > ul > li {
  background-color: #111827 !important;
}
.tab-group.tab-group-switch-style > ul li.active {
  background-color: #00A6FB !important;
  box-shadow: 1px 5px 5px #0582CA;
}
table caption {
  background-color: #006494 !important;
}
table {
  border-color: #131313;
  background-color: #181A1B;
  color: white;
}
table tr td,
table tr th {
  border-color: #131313 !important;
}
tbody {
  background-color: #181a1b !important;
}
th {
  background-color: #0e0f0f !important;
  color: white !important;
}
.tab-group.tab-group-switch-style > ul {
  border-color: #131313 !important;
}
#content {
  background-color: #181a1b !important;
}
</style>
